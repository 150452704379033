export const apiFieldsKey = {
  documentNumber: "document_number",
  manufactureYear: "manufacture_year",
  licensePlate: "license_plate",
  carId: "car_id",
  customerId: "customer_id",
  serviceOrderId: "service_order_id",
  serviceTypeId: "service_type_id",
  unitPrice: "unit_price",
  customerCarId: "customer_car_id",
  orderStatus: "order_status",
  servicePrice: "service_price",
  serviceItemsPrice: "service_items_price",
  discountPrice: "discount_price",
  odometerReading: "odometer_reading",
};
